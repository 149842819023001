import type { InjectionKey } from 'vue'

// models
import { NewsItem } from '@/models/news'

// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  GetNewsRequest,
  getNewsResponse,
} from '@/composables/repository/useNewsRepository'

// modules
import { isValueOf } from '@/utils/zod'

export type NewsStateType = {
  newsList: NewsItem[]
}

export const useNews = () => {
  const repositoryFactory = useRepositoryFactory()
  const newsRepository = repositoryFactory.get('news')

  const state = useState<NewsStateType>('news', () => ({
    newsList: [],
  }))

  // pager情報
  const limit = ref(18)
  const offset = ref(0)
  const total = ref(0)
  const page = ref(0)

  /**
   * ニュース一覧取得
   */
  const getNewsList = async (
    params?: Omit<GetNewsRequest, 'offset'> & {
      page?: number
    }
  ) => {
    limit.value = params?.limit || limit.value
    page.value = params?.page || 1
    offset.value = page.value * limit.value - limit.value

    const response = await newsRepository.get.getNews({
      limit: limit.value,
      offset: offset.value,
      ...params,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getNewsResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.newsList = response.news
    total.value = response.newsCount
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    getNewsList,
  }
}

export type NewsComposable = ReturnType<typeof useNews>

export const newsComposablesInjectionKey: InjectionKey<NewsComposable> =
  Symbol('news-composable')
